import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { DuplicateReportResponse, GetSavedReportsResponse } from '../types/reporting'

export class ReportingService extends ApiService {
  getSavedReports(): AxiosPromise<GetSavedReportsResponse> {
    return this.request({
      url: 'api/reports/names',
      method: 'GET',
    })
  }

  deleteReport(reportId: number): AxiosPromise<void> {
    return this.request({
      url: `api/reports/customreport/${reportId}`,
      method: 'DELETE',
    })
  }

  duplicateReport(reportId: number): AxiosPromise<DuplicateReportResponse> {
    return this.request({
      url: `api/reports/customreport/copy/${reportId}`,
      method: 'POST',
    })
  }
}
