import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import {
  FilePreview,
  GetCreativeFileForAdGroupExistsRequest,
  GetCreativeFileForAdGroupExistsResponse,
  GetCreativeFilesRequest,
  GetCreativeFilesResponse,
} from '../types/workflowCreativeFile'
import { cfUrl, workflowUiCfs } from '../consts/workflowUiCfs'

export class WorkflowCreativeFileService extends ApiService {
  uploadCreativeMapping<T = FilePreview>(file: FormData): AxiosPromise<T> {
    return this.request({
      url: 'api/audience/adminonlyfileupload',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: file,
    })
  }

  getCreativeFiles(payload: GetCreativeFilesRequest): AxiosPromise<GetCreativeFilesResponse> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.creativeFiles.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.creativeFiles.handlers.list,
          ...payload,
        }),
      },
    })
  }

  getCreativeFileForAdGroupExists(payload: GetCreativeFileForAdGroupExistsRequest): AxiosPromise<GetCreativeFileForAdGroupExistsResponse> {
    return this.request({
      url: cfUrl,
      method: 'POST',
      data: {
        name: workflowUiCfs.creativeFiles.cfName,
        body: JSON.stringify({
          type: workflowUiCfs.creativeFiles.handlers.doesCreativeFileForAdGroupExist,
          ...payload,
        }),
      },
    })
  }
}
